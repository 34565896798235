import React, { forwardRef } from 'react';
import { AccountConsumer } from '../providers/AccountProvider';
import { getContext } from '@/helpers';

const withAccountContext = (ConnectedComponent, properties = []) => {
	return forwardRef((props, ref) => {
		return (
			<AccountConsumer>
				{(context) => {
					let contextProps = properties.length ? getContext(context, properties) : {};

					return (
						<ConnectedComponent
							{...contextProps}
							{...props}
							ref={ref}
						/>
					);
				}}
			</AccountConsumer>
		);
	});
};

export default withAccountContext;
