import styled from 'styled-components';

const ImageStyle = styled.div.attrs((props) => ({ className: props.className }))`
	&.imageCont {
		position: relative;

		.image {
			object-fit: cover;
			object-position: center;
			transition: opacity 0.3s ease-out;
			z-index: 1;
		}

		&.backBlue {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: var(--color14);
				opacity: 0.3;
				z-index: 2;
			}
		}

		&.backBlack {
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: var(--color1);
				opacity: 0.5;
				z-index: 2;
			}
		}
	}
`;

export default ImageStyle;
