import api from './_api';
import config from './_config';
import { removeCookie } from './_functions';

export async function getUser(state, pathname, access_token) {
    const accountParams = getRouteObjFromConfig(pathname);
    let newState = state;
    let user, redirect, redirectUrl;

    if (!access_token && accountParams) {
        redirect = true;
    } else if (access_token) {
        user = await getInfo(access_token);
        newState = { ...newState, ...user };
    }

    if (!user && accountParams) {
        redirect = true;
        newState.isLoggedIn = false;
        newState.user = {};
    } else if (user) {
        newState.isLoggedIn = true;
    }

    redirectUrl = checkUserRedirection({ user: newState.user, pathname, params: accountParams, access_token });

    if (redirectUrl) {
        redirect = true;
    }

    return { newState, redirect, redirectUrl };
}

const getInfo = async (access_token) => {
    return await api.get('account', null, {}, access_token.value).catch((err) => {
        removeCookie('access_token');
    });
};

export function checkUserRedirection({ user, pathname, redirectAutomatically, router, access_token }) {
    const routes = config.routes;
    const accountParams = getRouteObjFromConfig(pathname);
    let redirectUrl;

    if ((!user && accountParams) || (user && !access_token)) {
        redirectUrl = routes.login.path;
    } else if (user && (pathname.includes(routes.login.path) || pathname.includes(routes.register.path) || pathname.includes(routes.resetPassword.path) || pathname.includes(routes.forgotPassword.path))) {
        redirectUrl = routes.account.path;
    }

    if (process.browser && redirectUrl && redirectAutomatically) {
        router.push(redirectUrl);
    }

    return redirectUrl;
}

const getRouteObjFromConfig = (pathname) => {
    const privateRoutes = Object.values(config.routes).filter((r) => r?.private === true);

    let path = pathname;

    const removedLastPath = path.substr(0, path.lastIndexOf('/'));

    let route = privateRoutes.filter((r) => {
        const pathArr = r.path.split(':');
        return r.path === path || r.path.replace(/\/$/, '') === path.replace(/\/$/, '') || (pathArr[0].replace(/\/$/, '') === removedLastPath && pathArr[1] && r.autoload);
    })[0];

    return pathname === '/404/' ? false : route;
};
