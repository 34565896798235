'use client';

import { useEffect, useState, useRef, createContext } from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';

import withUIContext from '../consumerHOC/UIConsumer';
import withDataContext from '../consumerHOC/DataConsumer';

import { config, api, checkUserRedirection, getCookie, setCookie, removeCookie } from '@/helpers';

const AccountContext = createContext({});
export const AccountConsumer = AccountContext.Consumer;

const AccountProvider = ({ selectedLang, ...props }) => {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = useSearchParams();

    const [state, setState] = useState({
        isLoggedIn: false,
        addresses: [],
        prevRoute: '/',
        ...props.newUser,
    });

    const previousRef = useRef();

    useEffect(() => {
        previousRef.current && setState((prev) => ({ ...prev, prevRoute: previousRef.current.prevRoute }));

        checkUserRedirection({
            user: state.user,
            pathname,
            redirectAutomatically: true,
            router,
            access_token: getCookie('access_token'),
        });

        return () => {
            previousRef.current = { prevRoute: pathname };
        };
    }, [pathname]);

    const asyncFunctionDecorator = (asyncFunc) => {
        return asyncFunc().catch((err) => {
            if (err.response.status === 422) {
                props.addErrorMsg('form', err.response.data.errors);
            }
            return Promise.reject(err.response);
        });
    };

    const updateAvatar = async (file) => {
        return await api.upload('update-avatar', file).then((res) => {
            setState((prev) => ({
                ...prev,
                user: res
            }));

            return Promise.resolve();
        });
    };

    const updateProfile = (data) => {
        return asyncFunctionDecorator(() =>
            api.post('update-profile', data, selectedLang).then((res) => {
                setState((prev) => ({
                    ...prev,
                    user: res
                }))
                return Promise.resolve();
            })
        );
    };

    const updatePassword = (data) => {
        return asyncFunctionDecorator(() =>
            api.post('update-password', data, selectedLang).then(() => {
                return Promise.resolve();
            })
        );
    };

    const createAddress = (formData) => {
        return asyncFunctionDecorator(() =>
            api.post('addresses', formData).then(({ data }) => {
                setState((prev) => ({
                    ...prev,
                    addresses: data,
                }));
            })
        );
    };

    const updateAddress = (id, formData) => {
        return asyncFunctionDecorator(() =>
            api.put(`addresses/${id}`, formData).then(({ data }) => {
                setState((prev) => ({
                    ...prev,
                    addresses: data,
                }));
            })
        );
    };

    const deleteAddress = async (id) => {
        return await api.delete(`addresses/${id}`).then(() => {
            const addresses = state.addresses;
            const index = addresses.findIndex((i) => i.id === id);
            addresses.splice(index, 1);

            setState((prev) => ({
                ...prev,
                addresses
            }));
        });
    };

    const setDefaultAddress = async (id) => {
        return await api.get(`addresses/${id}`, selectedLang).then(({ data }) => {
            setState({
                addresses: data,
            });
        });
    };

    const login = (data) => {
        return asyncFunctionDecorator(() =>
            api.post('login', data, selectedLang).then(({ accessToken, user, addresses }) => {
                setCookie('access_token', accessToken);

                setState(prev => ({
                    ...prev,
                    isLoggedIn: true,
                    user,
                    addresses
                }));

                router.push(searchParams.get('r') || state.prevRoute || config.routes.products.path);
            })
        );
    };

    const socialLogin = (provider) => {
        window.location.href = `${process.env.NEXT_PUBLIC_APP_API_SERVER}social-auth/${selectedLang}/${provider}`;
    };

    const register = (data) => {
        return asyncFunctionDecorator(() =>
            api.post('register', data, selectedLang).then(({ accessToken, user }) => {
                setCookie('access_token', accessToken);

                setState({
                    isLoggedIn: true,
                    user
                });
            })
        );
    };

    const logout = async () => {
        return await api.post('logout').then(() => {
            removeCookie('access_token');

            setState({
                isLoggedIn: false,
                addresses: [],
            });

            router.push('/');
        });
    };

    const forgotPassword = (data) => {
        return api.post('password/email', data);
    };

    const resetPassword = async (data) => {
        return await api.post('password/reset', data).then(() => {
            router.push('/login');
        });
    };

    const methods = {
        updateAvatar,
        updateProfile,
        updatePassword,
        createAddress,
        updateAddress,
        deleteAddress,
        setDefaultAddress,
        login,
        socialLogin,
        register,
        logout,
        forgotPassword,
        resetPassword
    };

    return (
        <AccountContext.Provider value={{ ...state, ...methods }}>
            {props.children}
        </AccountContext.Provider>
    )
};

export default withUIContext(withDataContext(AccountProvider, ['globalData']), ['addErrorMsg', 'removeErrorMsg']);
