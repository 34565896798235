'use client';

import { useEffect } from 'react';

const Page = ({ ...props }) => {
	useEffect(() => {
		window.history.scrollRestoration = 'manual';
	}, []);

	return <main className={`minHeightCont ${props.className || ''}`}>{props.children}</main>;
};

export default Page;
