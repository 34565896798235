export default {
	routes: {
		home: {
			path: '/',
			slug: 'home',
			name: 'home',
			autoload: true,
		},
		products: {
			path: '/menu',
			slug: 'products',
			name: 'products',
			autoload: true,
		},
		productCategory: {
			path: '/menu/:category',
			slug: 'products',
			name: 'products',
			autoload: true,
		},
		singleProduct: {
			path: '/menu/:category/:slug',
			slug: 'products',
			name: 'products',
			autoload: true,
		},
		search: {
			path: '/search',
			slug: 'products',
			name: 'products',
			autoload: false,
			postType: {
				slug: 'products',
				name: 'searchItems',
				queryParamKeys: ['search'], //* Don't change the order
			},
		},
		news: {
			path: '/news',
			slug: 'news',
			name: 'news',
			autoload: true,
			queryParams: true,
		},
		singleNews: {
			path: '/news/:slug',
			name: 'news',
			autoload: true,
		},
		aboutUs: {
			path: '/about-us',
			slug: 'about-us',
			name: 'about-us',
			autoload: true,
		},
		muramoney: {
			path: '/muramoney',
			slug: 'muramoney',
			name: 'muramoney',
			autoload: true,
		},
		contactUs: {
			path: '/contact-us',
			slug: 'contact',
			name: 'contact',
			autoload: true,
		},
		cart: {
			path: '/cart',
			slug: 'cart',
			name: 'cart',
			autoload: true,
		},
		thankYouMessage: {
			path: '/thank-you-message',
			slug: 'thank-you-message',
			name: 'thankYouMessage',
			autoload: true,
		},
		privacyPolicy: {
			path: '/privacy-policy',
			slug: 'privacy-policy',
			name: 'privacy-policy',
			autoload: true,
		},

		//! Private
		account: {
			path: '/account/',
			slug: 'account',
			name: 'account',
			autoload: true,
			private: true,
		},
		status: {
			path: '/account/my-status',
			slug: 'account-status',
			name: 'status',
			autoload: true,
			private: true,
		},
		addresses: {
			path: '/account/addresses',
			slug: 'account-addresses',
			name: 'addresses',
			autoload: true,
			private: true,
		},
		orders: {
			path: '/account/orders',
			slug: 'orders',
			name: 'orders',
			autoload: true,
			private: true,
		},
		checkout: {
			path: '/checkout',
			slug: 'checkout',
			name: 'checkout',
			autoload: true,
			private: true,
		},

		//! Auth
		login: {
			path: '/login',
			slug: 'login',
			name: 'login',
		},
		register: {
			path: '/register',
			slug: 'register',
			name: 'register',
		},
		forgotPassword: {
			path: '/forgot-password',
			slug: 'forgot-password',
			name: 'forgot-password',
		},
		resetPassword: {
			path: '/reset-password',
			slug: 'reset-password',
			name: 'reset-password',
		},
	},
};
