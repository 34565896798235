import { createGlobalStyle, css } from 'styled-components';

const Typography = createGlobalStyle`${css`
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		outline: none;
		/* user-select: none; */
		/* -ms-user-select: none; */
		/* -moz-user-select: none; */
		-webkit-appearance: none;
		/* -webkit-user-select: none; */
		-webkit-touch-callout: none;
		-webkit-text-size-adjust: 100%;
		-webkit-overflow-scrolling: touch;
		-webkit-font-smoothing: antialiased;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important; //! Touch highlight color
	}

	body {
		width: 100%;
		min-height: 100vh;
		font-family: arm-regular;
		font-weight: 400;
		line-height: var(--lineHeight);
		color: var(--white);
		background-color: var(--black);
		position: relative;
		overflow-x: hidden;

		main.page {
			min-height: 100vh;
			z-index: 1;
			position: relative;
		}

		/* ::-webkit-scrollbar {
			display: none;
		} */

		&.hidden {
			overflow: hidden !important;
			max-height: 100dvh !important;
		}

		/* //! For IOS */
		&.touch-action {
			* {
				touch-action: none;
				-ms-touch-action: none;
			}
		}
	}

	ul,
	fieldset {
		list-style: none;
		border: none;
	}

	a {
		text-decoration: none;
		color: inherit;
	}

	input[type='search']::-webkit-search-decoration,
	input[type='search']::-webkit-search-cancel-button,
	input[type='search']::-webkit-search-results-button,
	input[type='search']::-webkit-search-results-decoration {
		-webkit-appearance: none;
	}

	textarea,
	input[type='text'],
	input[type='button'],
	input[type='submit'] {
		-webkit-appearance: none;
		border-radius: 0;
		margin: 0;
	}

	textarea {
		width: 100%;
		min-height: var(--textareaMinHeight);
		padding: var(--textareaPadding);
		margin-top: var(--sp2x);
		font-size: var(--h10);
		resize: none;

		&,
		&:focus {
			border: 1px solid ${(props) => props.theme.colors.black} !important;
		}
	}

	textarea::-webkit-input-placeholder {
		color: ${(props) => props.theme.colors.black};
		font-size: var(--h10);
	}

	textarea:-moz-placeholder {
		color: ${(props) => props.theme.colors.black};
		font-size: var(--h10);
	}

	textarea::-moz-placeholder {
		color: ${(props) => props.theme.colors.black};
		font-size: var(--h10);
	}

	textarea:-ms-input-placeholder {
		color: ${(props) => props.theme.colors.black};
		font-size: var(--h10);
	}

	textarea::placeholder {
		color: ${(props) => props.theme.colors.black};
		font-size: var(--h10);
	}

	input:-internal-autofill-selected {
		background-color: transparent !important;
		box-shadow: inset 1000px 1000px #fff;
	}

	input:focus::placeholder {
		color: transparent;
	}

	input {
		outline: none;
	}

	input:-webkit-autofill {
		-webkit-box-shadow: inset 0 0 0 50px #fff !important;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: 2px solid var(--white);
		-webkit-text-fill-color: var(--white);
		color: var(--blue);
		background-color: transparent;
		-webkit-box-shadow: 0 0 0 1000px transparent inset;
		transition: background-color 5000s ease-in-out 0s;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px transparent inset !important;
	}

	@-webkit-keyframes autofill {
		to {
			background: transparent;
		}
	}

	input,
	textarea,
	select,
	button {
		&:focus {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
			outline: none !important;
		}
	}

	.h1,
	h1 {
		font-size: var(--h1);
		line-height: var(--lineHeightS);
	}

	.h2,
	h2 {
		font-size: var(--h2);
		line-height: var(--lineHeightS);
	}

	.h3,
	h3 {
		font-size: var(--h3);
		line-height: var(--lineHeightS);
	}

	.h4,
	h4 {
		font-size: var(--h4);
		line-height: var(--lineHeightS);
	}

	.h5,
	h5 {
		font-size: var(--h5);
		line-height: var(--lineHeightS);
	}

	.h6,
	h6 {
		font-size: var(--h6);
		line-height: var(--lineHeightS);
	}

	.h7 {
		font-size: var(--h7);
		line-height: var(--lineHeightS);
	}

	.h8 {
		font-size: var(--h8);
		line-height: var(--lineHeightS);
	}

	.h9 {
		font-size: var(--h9);
		line-height: var(--lineHeight);
	}

	.h10 {
		font-size: var(--h10);
		line-height: var(--lineHeight);
	}

	.p,
	p {
		font-size: var(--p);
		line-height: var(--lineHeight);
	}

	.pSm {
		font-size: var(--pSm);
		line-height: var(--lineHeight);
	}

	.pMd {
		font-size: var(--pMd);
		line-height: var(--lineHeight);
	}
`}`;

export default Typography;
