'use client';

import { forwardRef, useRef, useImperativeHandle, useState } from 'react';
import Image from 'next/image';

//* Style
import ImageStyle from './style';

const CustomImage = forwardRef(({ src, alt, className, width, height, priority = false, onClick, onMouseEnter, onMouseLeave, onLoad }, ref) => {
    //! Ref
    const imgRef = useRef();

    //! State
    const [loaded, setLoaded] = useState(false);

    //! Imperative Handle
    useImperativeHandle(ref, () => imgRef.current, []);

    //! Handle Image Load
    const onLoadCallback = () => {
        setLoaded(true);

        typeof onLoad === 'function' && onLoad(loaded);
    };

    return (
        <ImageStyle
            style={{ width, height }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`imageCont ${className || ''}`}>
            <Image
                fill
                ref={imgRef}
                draggable={false}
                onClick={onClick}
                placeholder='blur'
                className={'image'}
                priority={priority}
                alt={alt || 'Image'}
                onLoad={onLoadCallback}
                sizes='(max-width: 767px) 100%'
                src={src || '/images/default.jpeg'}
                style={{ opacity: loaded ? 1 : 0 }}
                loading={priority ? 'eager' : 'lazy'}
                blurDataURL={src || '/images/default.jpeg'}
            />
        </ImageStyle>
    );
});

CustomImage.displayName = 'CustomImage';

export default CustomImage;
