export default {
	colors: {
		black: '#000',
		blackOpacity: 'rgba(0, 0, 0, 0.80)',
		white: '#fff',
		grey: '#5A5A5A',
		grey2: '#AFAFAF',
		grey3: '#777777',
		greyDark: '#242424',
		greyMid: '#939393',
		greyLight: '#BEBEBE',
		greyExLight: '#EFEFEF',
		errorColor: '#e51a1a',
		borderColor: '#C000FF',
		borderDark: '#3C3C3C',
		borderColor2: '#C026FC',
	},

	mediaQuery: {
		DesktopSizeL: '2560px',
		DesktopSizeM: '1920px',
		DesktopSizeS: '1440px',
		LaptopSize: '1024px',
		TabletSize: '768px',
		MobileSize: '601px',

		DesktopSizeLMin: '2559px',
		DesktopSizeMMin: '1919px',
		DesktopSizeSMin: '1439px',
		LaptopSizeMin: '1023px',
		TabletSizeMin: '767px',
		MobileSizeMin: '600px',
	},
};
