import React from 'react';
import Cookie from 'js-cookie';
import _ from 'lodash';

export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const getCookie = (key) => {
    return Cookie.get(key);
};

export const setCookie = (key, cookie) => {
    Cookie.set(key, cookie);
};

export const removeCookie = (key) => {
    Cookie.remove(key);
};


export function letterify(word) {
    let arr = word.trim().split('');

    return arr.map((l, k) => (
        <span
            className={`letter ${l === ' ' ? 'space' : ''}`}
            key={k}>
            {l}
        </span>
    ));
}


export const stringToLetters = (text) => {
    let wordLength = text.length;
    let arr = [];

    for (let i = 0; i < wordLength; i++) {
        arr.push(text.charAt(i));
    }

    return (
        <div className='word'>
            {arr.map((i, k) => {
                return (
                    <span
                        className='letter'
                        key={k}>
                        {i}
                    </span>
                );
            })}
        </div>
    );
};

export const breakString = (str, hasSpan = true, symb, className = '') => {
    let arr = str.split(symb || '</br>');

    if (!hasSpan) {
        return arr;
    }

    return arr.map((i, k) => (
        <span
            className={`${className || 'item'}-${k + 1}`}
            key={k}>
            {i}
        </span>
    ));
};

export const queryStringToObject = (url) => {
    return _.chain(url).replace('?', '').split('&').map(_.partial(_.split, _, '=', 2)).fromPairs().value();
};

export const objectToQueryString = (obj) => {
    if (obj) {
        const results = [];
        _.forOwn(obj, (value, key) => {
            if (Array.isArray(value)) {
                _.forOwn(value, (value) => {
                    results.push(`${key}=${value}`);
                });
            } else {
                results.push(`${key}=${value}`);
            }
        });
        return results.join('&');
    }
    return '';
};

export const getPageNumbers = ({ currentPage, pageSize, total, pageNumbersToShow = 3 }) => {
    const lastPageNumber = Math.ceil(total / pageSize);
    const currentPageNumber = currentPage <= lastPageNumber ? currentPage : lastPageNumber;
    const maxPagesBeforeCurrentPage = Math.floor(pageNumbersToShow / 2);
    const maxPagesAfterCurrentPage = Math.ceil(pageNumbersToShow / 2) - 1;
    let startPage = 1;
    let endPage = lastPageNumber;

    if (lastPageNumber <= 1) {
        return []; // Don't show numbers if there's only 1 page
    }

    if (currentPageNumber <= maxPagesBeforeCurrentPage) {
        // near the start
        startPage = 1;
        endPage = pageNumbersToShow;
    } else if (currentPageNumber + maxPagesAfterCurrentPage >= lastPageNumber) {
        // near the end
        startPage = lastPageNumber - pageNumbersToShow + 1;
    } else {
        // somewhere in the middle
        startPage = currentPageNumber - maxPagesBeforeCurrentPage;
        endPage = currentPageNumber + maxPagesAfterCurrentPage;
    }

    let pageNumbers = Array.from(Array(endPage + 1 - startPage).keys())
        .map((pageNumber) => startPage + pageNumber)
        .filter((pageNumber) => pageNumber <= lastPageNumber && pageNumber > 0);

    if (pageNumbers[0] > 1) {
        if (pageNumbers[0] <= 2) {
            pageNumbers = [1, ...pageNumbers];
        } else {
            const ellipsis = pageNumbers[0] > 3 ? '...' : 2;
            pageNumbers = [1, ellipsis, ...pageNumbers];
        }
    }

    if (pageNumbers[pageNumbers.length - 1] !== lastPageNumber) {
        if (pageNumbers[pageNumbers.length - 1] === lastPageNumber - 1) {
            pageNumbers = [...pageNumbers, lastPageNumber];
        } else {
            const ellipsis = pageNumbers[pageNumbers.length - 1] < lastPageNumber - 2 ? '...' : lastPageNumber - 1;
            pageNumbers = [...pageNumbers, ellipsis, lastPageNumber];
        }
    }

    return pageNumbers;
};

export const getParamsByKey = (params, key) => {
    return params.get(key)?.split(',');
};

export const createQueryUrl = ({ url, searchParams }) => {
    const params = new URLSearchParams(searchParams);
    const lang = getCookie('lang');

    if (lang != 'hy') {
        url = `/${lang}` + url
    }

    if (params.size > 0) {
        return `${url}?${params.toString()}`
    }

    return url;
}

export const createQueryString = ({ searchParams, typeId, type, multiple }) => {
    const params = new URLSearchParams(searchParams);

    let newVal = typeId?.toString();

    if (multiple) {
        let paramsArr = getParamsByKey(params, type);

        if (paramsArr) {
            paramsArr = paramsArr.indexOf(newVal) > -1 ? paramsArr.filter((k) => k !== newVal) : [...paramsArr, newVal];
            newVal = paramsArr.join(',');
        }
    }

    if (!newVal || params.has(type, newVal)) {
        params.delete(type);
    } else {
        params.set(type, newVal);
    }

    if (type != 'page' && params.has('page')) {
        params.delete('page');
    }

    return params.toString();
};
