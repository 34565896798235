import { createGlobalStyle, css } from 'styled-components';

//! Space Generator
function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 200; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

const Variables = createGlobalStyle`${css`
	:root {
		/* //! Colors */
		--black: ${(props) => props.theme.colors.black};
		--blackOpacity: ${(props) => props.theme.colors.blackOpacity};
		--white: ${(props) => props.theme.colors.white};
		--grey: ${(props) => props.theme.colors.grey};
		--grey2: ${(props) => props.theme.colors.grey2};
		--grey3: ${(props) => props.theme.colors.grey3};
		--greyDark: ${(props) => props.theme.colors.greyDark};
		--greyMid: ${(props) => props.theme.colors.greyMid};
		--greyLight: ${(props) => props.theme.colors.greyLight};
		--greyExLight: ${(props) => props.theme.colors.greyExLight};
		--borderColor: ${(props) => props.theme.colors.borderColor};
		--borderColor2: ${(props) => props.theme.colors.borderColor2};
		--borderDark: ${(props) => props.theme.colors.borderDark};
		--errorColor: ${(props) => props.theme.colors.errorColor};

		//! Spacings
		--spSize: 8px;

		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp1-3x: calc(var(--spSize) * 1.3);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp1-6x: calc(var(--spSize) * 1.7);
		--sp2-5x: calc(var(--spSize) * 2.5);
		--sp3-5x: calc(var(--spSize) * 3.5);
		--sp4-5x: calc(var(--spSize) * 4.5);
		--sp5-5x: calc(var(--spSize) * 5.4);
		--sp6-5x: calc(var(--spSize) * 6.5);
		--sp8-5x: calc(var(--spSize) * 8.5);
		${spaceGenerator()};

		//! Screen Sizes
		--DesktopSizeL: ${(props) => props.theme.mediaQuery.DesktopSizeL};
		--DesktopSizeM: ${(props) => props.theme.mediaQuery.DesktopSizeM};
		--DesktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
		--LaptopSize: ${(props) => props.theme.mediaQuery.LaptopSize};
		--TabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
		--MobileSize: ${(props) => props.theme.mediaQuery.MobileSize};

		//! Transitions
		--trTime: 0.3s;
		--trTime2: 0.5s;
		--trTime3: 0.7s;

		//! Line Heights
		--lineHeightXS: 1.1;
		--lineHeightS: 1.3;
		--lineHeight: 1.6;
		--lineHeightL: 1.9;
	}

	/* //! 2560 */
	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		:root {
			--h1: 128px;
			--h2: 64px;
			--h3: 56px;
			--h4: 48px;
			--h5: 40px;
			--h6: 32px;
			--h7: 32px;
			--h8: 28px;
			--h9: 24px;
			--h10: 22px;
			--p: 20px;
			--pSm: 16px;

			--marginBottom: 240px;
			--marginTop: 110px;
			--containerPadding: var(--sp27x);
			--headerPaddingTop: var(--sp8x);
			--headerPaddingLeft: var(--sp10x);
			--iconSize: 30px;
			--logoSize: 300px;
			--textareaPadding: var(--sp3x);
			--textareaMinHeight: var(--sp28x);
		}
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		:root {
			--h1: 96px;
			--h2: 56px;
			--h3: 48px;
			--h4: 40px;
			--h5: 32px;
			--h6: 28px;
			--h7: 28px;
			--h8: 24px;
			--h9: 22px;
			--h10: 18px;
			--p: 16px;
			--pSm: 14px;

			--marginBottom: 200px;
			--marginTop: 85px;
			--containerPadding: var(--sp17x);
			--headerPaddingTop: var(--sp5x);
			--headerPaddingLeft: var(--sp8x);
			--iconSize: 28px;
			--logoSize: 250px;
			--textareaPadding: var(--sp3x);
			--textareaMinHeight: var(--sp24x);
		}
	}

	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		:root {
			--h1: 80px;
			--h2: 48px;
			--h3: 40px;
			--h4: 40px;
			--h5: 32px;
			--h6: 28px;
			--h7: 24px;
			--h8: 22px;
			--h9: 20px;
			--h10: 16px;
			--p: 16px;
			--pSm: 14px;

			--marginBottom: 180px;
			--marginTop: 80px;
			--containerPadding: var(--sp14x);
			--headerPaddingTop: var(--sp5x);
			--headerPaddingLeft: var(--sp7x);
			--iconSize: 26px;
			--logoSize: 230px;
			--textareaPadding: var(--sp3x);
			--textareaMinHeight: var(--sp22x);
		}
	}

	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
		:root {
			--h1: 56px;
			--h2: 40px;
			--h3: 32px;
			--h4: 32px;
			--h5: 28px;
			--h6: 24px;
			--h7: 22px;
			--h8: 20px;
			--h9: 16px;
			--h10: 16px;
			--p: 14px;
			--pSm: 14px;

			--marginBottom: 140px;
			--marginTop: 80px;
			--containerPadding: var(--sp10x);
			--headerPaddingTop: var(--sp4x);
			--headerPaddingLeft: var(--sp4x);
			--iconSize: 24px;
			--logoSize: 210px;
			--textareaPadding: var(--sp3x);
			--textareaMinHeight: var(--sp22x);
		}
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		:root {
			--h1: 56px;
			--h2: 40px;
			--h3: 32px;
			--h4: 32px;
			--h5: 28px;
			--h6: 24px;
			--h7: 22px;
			--h8: 20px;
			--h9: 16px;
			--h10: 16px;
			--p: 14px;
			--pSm: 14px;

			--marginBottom: 140px;
			--marginTop: 77px;
			--containerPadding: var(--sp8x);
			--headerPaddingTop: var(--sp4x);
			--headerPaddingLeft: var(--sp4x);
			--logoSize: 190px;
			--iconSize: 20px;
			--textareaPadding: var(--sp3x);
			--textareaMinHeight: var(--sp22x);
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) {
		:root {
			--h1: 32px;
			--h2: 24px;
			--h3: 24px;
			--h4: 24px;
			--h5: 24px;
			--h6: 20px;
			--h7: 20px;
			--h8: 18px;
			--h9: 14px;
			--h10: 12px;
			--p: 12px;
			--pSm: 10px;
			--pMd: 18px;

			--marginBottom: 105px;
			--marginTop: 60px;
			--containerPadding: var(--sp2x);
			--headerPaddingTop: var(--sp2x);
			--headerPaddingLeft: var(--sp2x);
			--iconSize: 20px;
			--logoSize: 28px;
			--textareaPadding: var(--sp2x);
			--textareaMinHeight: var(--sp20x);
		}
	}
`}`;

export default Variables;
