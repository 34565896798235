import { createGlobalStyle, css } from 'styled-components';

const HelperClass = createGlobalStyle`${css`
	.minHeightCont {
		padding: 0.1px 0;
		min-height: 100vh;
	}

	.MuiBackdrop-root {
		background-color: rgba(0, 0, 0, 0.8) !important;
	}

	.Contact {
		height: 100%;
		padding: 1px 0;
		overflow: hidden;
	}

	.cart {
		min-height: 100vh;
		background-color: white;
	}

	.modal-open {
		overflow-y: hidden;

		/* @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
			.productMob {
				> div:first-child {
					opacity: 0;
					pointer-events: none;
				}
			}
		} */
	}

	/* @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) {
		.productMob {
			> div:first-child {
				transition: all var(--trTime3);
			}
		}
	} */

	.FlexBoxContainer {
		display: flex;
		flex-wrap: wrap;
	}

	.FlexBoxColumn {
		display: flex;
		flex-direction: column;
	}

	.FlexBox {
		display: flex;
	}

	.alignMiddle {
		align-items: center;
	}

	.alignCenter {
		justify-content: center;
	}

	.AlignEnd {
		align-items: flex-end;
	}

	.JustifyEnd {
		justify-content: flex-end;
	}

	.spaceBetween {
		justify-content: space-between;
	}

	.cursorPointer {
		cursor: pointer;
	}

	.underline {
		text-decoration: underline;
	}

	.uppercase {
		text-transform: uppercase;
	}

	.capitalize {
		text-transform: capitalize;
	}

	.lineThrough {
		text-decoration: line-through;
	}

	.arm-regular {
		font-family: arm-regular;
	}

	.arm-bold {
		font-family: arm-bold;
	}

	//! Colors
	.black {
		color: var(--black);
	}

	.white {
		color: var(--white);
	}

	.grey {
		color: var(--grey);
	}

	.grey2 {
		color: var(--grey2);
	}

	.grey3 {
		color: var(--grey3);
	}

	.greyMid {
		color: var(--greyMid);
	}

	.greyLight {
		color: var(--greyLight);
	}

	.greyExLight {
		color: var(--greyExLight);
	}

	.greyDark {
		color: var(--greyDark);
	}

	.borderColor {
		color: var(--borderColor2);
	}

	.red {
		color: var(--errorColor);
	}

	.relativeWrap {
		position: relative;
	}

	.overflowWrap {
		overflow: hidden;
		padding: 1px 0;
	}

	.overflowScroll {
		overflow: scroll;
		height: 100%;
	}

	.paragraphWrapp {
		--paragrafMarg: var(--sp4x);
		--listMargBott: var(--sp2x);
		--listPadd: var(--sp2x);

		p {
			margin-bottom: var(--paragrafMarg);

			&:last-child {
				margin-bottom: 0;
			}
		}

		ul {
			padding-left: var(--listPadd);
			margin-bottom: var(--paragrafMarg);

			li {
				list-style-type: square;
				margin-bottom: var(--listMargBott);
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.LaptopSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			--paragrafMarg: var(--sp4x);
			--listMargBott: var(--sp2x);
			--listPadd: var(--sp5x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.LaptopSize}) {
			--paragrafMarg: var(--sp4x);
			--listMargBott: var(--sp2x);
			--listPadd: var(--sp6x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
			--paragrafMarg: var(--sp5x);
			--listMargBott: var(--sp2x);
			--listPadd: var(--sp9x);
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
			--paragrafMarg: var(--sp5x);
			--listMargBott: var(--sp2x);
			--listPadd: var(--sp9x);
		}

		@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
			--paragrafMarg: var(--sp8x);
			--listMargBott: var(--sp3x);
			--listPadd: var(--sp9x);
		}
	}

	.loader {
		&.homeLoader {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100vw;
			height: 100vh;
			z-index: 1000;
			pointer-events: none;

			&:before {
				background-color: var(--black);
			}

			.cont {
				width: 200px;
				height: 200px;

				span {
					background-color: var(--white);
					height: 40px;

					&.line-top {
						bottom: calc(100% + 40px);
					}

					&.line-bottom {
						top: calc(100% + 40px);
					}
				}
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: 0.5s linear 2s;
		}

		&.start {
			&:before {
				opacity: 0;
			}

			.cont {
				transform: translate(-50%, -50%) scale(0);

				span {
					width: 100%;
				}

				.circle {
					&:before {
						top: 0;
					}
				}
			}
		}

		.cont {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 200px;
			height: 200px;
			transition: 0.5s linear 1.5s;

			span {
				width: 0;
				height: 40px;
				position: absolute;
				transition: 0.5s ease-out 1s;

				&.line-top {
					bottom: calc(100% + 40px);
					left: 0;
				}

				&.line-bottom {
					top: calc(100% + 40px);
					right: 0;
				}
			}

			.circle {
				border-radius: 50%;
				overflow: hidden;
				width: 100%;
				height: 100%;
				position: relative;
				background-color: var(--greyDark);

				&:before {
					content: '';
					position: absolute;
					top: 100%;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(45deg, rgba(167, 0, 222, 1) 0%, rgba(254, 0, 133, 1) 100%);
					transition: 1s ease-in-out;
				}
			}
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			.cont {
				width: 100px;
				height: 100px;

				span {
					height: 20px;

					&.line-top {
						bottom: calc(100% + 20px);
					}

					&.line-bottom {
						top: calc(100% + 20px);
					}
				}
			}
		}
	}

	.accountLoader {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		pointer-events: none;

		&:before {
			background-color: var(--white);
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: 1s linear 1s;
		}

		.cont {
			position: absolute;
			top: 50vh;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100px;
			height: 100px;
			transition: 0.5s linear 1.5s;

			span {
				background-color: var(--black);
				height: 20px;
				width: 0;
				position: absolute;
				transition: 0.5s ease-out 1s;

				&.line-top {
					bottom: calc(100% + 20px);
					left: 0;
				}

				&.line-bottom {
					top: calc(100% + 20px);
					right: 0;
				}
			}

			.circle {
				border-radius: 50%;
				overflow: hidden;
				width: 100%;
				height: 100%;
				position: relative;
				background-color: var(--greyDark);

				&:before {
					content: '';
					position: absolute;
					top: 100%;
					left: 0;
					width: 99%;
					height: 99%;
					background: linear-gradient(45deg, rgba(167, 0, 222, 1) 0%, rgba(254, 0, 133, 1) 100%);
					transition: 0.5s ease-in-out;
				}
			}
		}

		&.start {
			&:before {
				opacity: 0;
			}

			.cont {
				transform: translate(-50%, -50%) scale(0);

				span {
					width: 100%;
				}

				.circle {
					&:before {
						top: 0;
					}
				}
			}
		}

		&.end {
			opacity: 0;
			visibility: hidden;
		}

		@media only screen and (max-width: ${(props) => props.theme.mediaQuery.MobileSize}) {
			.cont {
				width: 50px;
				height: 50px;

				span {
					height: 10px;

					&.line-top {
						bottom: calc(100% + 10px);
					}

					&.line-bottom {
						top: calc(100% + 10px);
					}
				}
			}
		}
	}
`}`;

export default HelperClass;
