//* Providers
import DataProvider from './providers/DataProvider';
import LanguageProvider from './providers/LanguageProvider';
import UIProvider from './providers/UIProvider';
import AccountProvider from './providers/AccountProvider';
import CartProvider from './providers/CartProvider';

//* ConsumerHOC
import withDataContext from './consumerHOC/DataConsumer';
import withLanguageContext from './consumerHOC/LanguageConsumer';
import withUIContext from './consumerHOC/UIConsumer';
import withAccountContext from './consumerHOC/AccountConsumer';
import withCartContext from './consumerHOC/CartConsumer';

export { DataProvider, LanguageProvider, UIProvider, AccountProvider, CartProvider, withDataContext, withLanguageContext, withUIContext, withAccountContext, withCartContext };
