'use client';

import { useState, useMemo, createContext } from 'react';

//* Context
import withLanguageContext from '../consumerHOC/LanguageConsumer';
import { api } from '@/helpers';


const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

const DataProvider = (props) => {
    const [state, setState] = useState({
        ...props.newData,
        prevRoute: null,
    });

    const globalData = useMemo(() => state.globalData?.[props.selectedLang], [props.selectedLang, state]);

    const visitCategory = (slug) => {
        api.post('visit-category', { slug })
    }

    const methods = {
        visitCategory
    };

    return <DataContext.Provider value={{ ...state, ...methods, globalData }}>{props.children}</DataContext.Provider>;
};

export default withLanguageContext(DataProvider, ['selectedLang']);
