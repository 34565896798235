'use client';

import { usePathname, useRouter } from 'next/navigation';
import { createContext, useEffect, useState } from 'react';

//* HOC's
import withAccountContext from '../consumerHOC/AccountConsumer';
import withUIContext from '../consumerHOC/UIConsumer';

//* Helpers
import { api, getCookie, removeCookie, setCookie } from '@/helpers';

const CartContext = createContext({});
export const CartConsumer = CartContext.Consumer;

const CartProvider = ({ selectedLang, isLoggedIn, children }) => {
    const router = useRouter();
    const pathname = usePathname();

    const [state, setState] = useState({
        items_quantity: 0,
        subtotal: 0,
        delivery: '-',
        code: undefined,
        code_total: undefined,
        isMuramoney: 0,
        total: 0,
        items: [],
        checkSyncCart: false,
        checkoutAddressId: undefined,
        isCartLoading: true,
    });

    const setResourceDecorator = (data, additional = {}) => {
        setState((prev) => ({
            ...prev,
            ...data,
            ...additional,
        }));
    };

    const getCookieItems = () => {
        const cookieCart = getCookie('cart');
        return cookieCart ? JSON.parse(cookieCart) : [];
    };

    const getCart = async (addressId) => {
        if (isLoggedIn) {
            let params = {};

            addressId && (params.addressId = addressId);

            return await api.get('cart', selectedLang, params).then((res) => setResourceDecorator(res.data, { checkoutAddressId: addressId, isCartLoading: false }));
        } else {
            const cookieItems = getCookieItems();

            if (cookieItems) {
                return await api.post('guest-cart', { cart: cookieItems }).then(({ data }) => setResourceDecorator(data, { isCartLoading: false }));
            }
        }
    };

    const syncViewCart = () => {
        removeCookie('cart')
        setState((prev) => ({
            ...prev,
            checkSyncCart: false,
        }));
    };

    const syncCart = () => {
        const cookieItems = getCookieItems()

        api.post('sync-cart', { items: cookieItems }, selectedLang).then(({ data }) => {
            removeCookie('cart')
            setResourceDecorator(data, { checkSyncCart: false });
        });
    };

    const removeCart = async (productId) => {
        if (isLoggedIn) {
            return await api.post('delete-cart-item', { id: productId }, selectedLang).then((res) => setResourceDecorator(res.data));
        } else {
            const items = getCookieItems();

            const index = items.findIndex((item) => item.productId === productId);

            items.splice(index, 1);

            setCookie('cart', JSON.stringify(items).trim());

            getCart();
        }
    };

    const guestAddToCart = (productId, quantity) => {
        const cookieItems = getCookieItems();
        const existingItem = cookieItems.find((item) => item.productId === productId);

        let items = [];

        if (!existingItem) {
            items = cookieItems.concat({ productId, quantity });
        } else {
            items = cookieItems.map((item) =>
                item.productId === productId
                    ? Object.assign({}, item, {
                        quantity,
                        productId,
                    })
                    : item
            );
        }

        setCookie('cart', JSON.stringify(items).trim());

        getCart();

        return Promise.resolve();
    };

    const customerAddToCart = async (productId, quantity) => {
        return await api.post('add-to-cart', { product_id: productId, quantity: quantity }, selectedLang).then((res) => setResourceDecorator(res.data));
    };

    const addToCart = (productId, quantity, price, update = false) => {
        if (!isLoggedIn) {
            guestAddToCart(productId, quantity, price, update);
        } else {
            customerAddToCart(productId, quantity);
        }
    };

    const checkPromocode = async (data) => {
        return await api
            .post('check-promocode', data, selectedLang)
            .then((res) => setResourceDecorator(res.data))
            .catch((err) => {
                setResourceDecorator(err.response.data.cart);
                return Promise.reject(err.response.data.errors);
            });
    };

    const fillBalance = async (data) => {
        return await api.post('fill-balance', data, selectedLang).then((res) => setResourceDecorator(res.data, { isMuramoney: data.muramoney }));
    };

    const deletePromocode = async () => {
        return await api.post('delete-promocode', {}, selectedLang).then((res) => setResourceDecorator(res.data));
    };

    const reorder = async (id) => {
        return await api.post('reorder', { id: id }, selectedLang).then((res) => {
            setResourceDecorator(res.data);
            router.push('/checkout');
        });
    };

    const order = (paymentType, note) => {
        return api.post(
            'orders',
            {
                paymentType: paymentType,
                addressId: state.checkoutAddressId,
                code: state?.code?.id,
                isMuramoney: state.isMuramoney,
                note,
            },
            selectedLang
        );
    };

    useEffect(() => {
        getCart();
    }, [isLoggedIn]);

    useEffect(() => {
        if (pathname.includes('cart') || pathname.includes('checkout')) {
            if (!state.checkSyncCart && isLoggedIn) {
                const cookieItems = getCookieItems();

                if (cookieItems.length) {
                    setState(prev => ({
                        ...prev,
                        checkSyncCart: true
                    }))
                }
            }
        }
    }, [pathname, isLoggedIn])


    const methods = {
        addToCart,
        removeCart,
        syncViewCart,
        syncCart,
        getCart,
        checkPromocode,
        deletePromocode,
        order,
        reorder,
        fillBalance,
    };

    return (
        <CartContext.Provider value={{ ...state, ...methods }}>
            {children}
        </CartContext.Provider>
    );
};

export default withAccountContext(withUIContext(CartProvider, ['addErrorMsg', 'removeErrorMsg']), ['isLoggedIn']);
