'use client';

import { useRef, useEffect, useCallback } from 'react';
import { gsap } from 'gsap';

//* Components
import Text from '../Text';

const Title = (props) => {
	//! Refs
	const ref = useRef();
	const contRef = useRef();

	useEffect(() => {
		gsap.set(ref?.current, { y: ref?.current?.clientHeight });

		setTimeout(() => {
			if (contRef?.current?.getBoundingClientRect().y < window.innerHeight) {
				gsap.to(ref?.current, 1, { y: 0, ease: 'power3.out' });
			}
		}, 300);

		window.addEventListener('scroll', scroll);

		return () => {
			window.removeEventListener('scroll', scroll);
		};
	}, []);

	const scroll = useCallback(() => {
		if (contRef?.current?.getBoundingClientRect().y < window.innerHeight * 0.7) {
			gsap.to(ref?.current, 1, { y: 0, ease: 'power3.out' });
		}
	}, []);

	return (
		<div
			ref={contRef}
			style={{ overflow: 'hidden' }}
			className={`title-cont ${props.contClassName}`}>
			<Text
				ref={ref}
				tag={props.tag}
				text={props.text}
				className={props.className}
				uppercase={props.uppercase}
			/>
		</div>
	);
};

export default Title;
