'use client';

import { Component, createContext } from 'react';
import { ThemeProvider } from 'styled-components';

//* Style
import themes from '@/styles/theme';
import HelperClass from '@/styles/helperClass';
import Variables from '@/styles/variables';
import Typography from '@/styles/typography';

const UIContext = createContext(null);
export const UIConsumer = UIContext.Consumer;

const errorsInitialState = {
    formError: {
        error: false,
        names: [],
        msg: {},
    },
    systemError: {
        error: false,
        msg: '',
    },
};

class UIProvider extends Component {
    //! States
    state = {
        popupIsOpen: false,
        highComponent: null,
        popupComponent: null,
        loading: false,
        preloader: true,
        winWidth: 1920,
        winHeight: 1080,
        screenSizes: {
            DesktopSizeL: '',
            DesktopSizeM: '',
            DesktopSizeS: '',
            LaptopSize: '',
            TabletSize: '',
            MobileSize: '',
        },
        ...errorsInitialState,
    };

    //! Methods
    methods = {
        addHighComponent: this.addHighComponent.bind(this),
        removeHighComponent: this.removeHighComponent.bind(this),
        openPopup: this.openPopup.bind(this),
        closePopup: this.closePopup.bind(this),
        enablePreloader: this.enablePreloader.bind(this),
        disablePreloader: this.disablePreloader.bind(this),
        disableDomScroll: this.disableDomScroll.bind(this),
        enableDomScroll: this.enableDomScroll.bind(this),
        addErrorMsg: this.addErrorMsg.bind(this),
        removeErrorMsg: this.removeErrorMsg.bind(this),
    };

    //! Esc Keydown
    escKeydown = (event) => {
        if (event.keyCode === 27) {
            this.state.popupIsOpen && !this.state.preloader && !this.state.loading && this.closePopup();
        }
    };

    //! Screen Resize
    screenResize = () => {
        this.setState({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    //! Get Media Screen Sizes from Css Variables
    getScreenSizes() {
        const root = getComputedStyle(document.documentElement);

        const screenSizes = {
            DesktopSizeL: parseInt(root.getPropertyValue('--DesktopSizeL')),
            DesktopSizeM: parseInt(root.getPropertyValue('--DesktopSizeM')),
            DesktopSizeS: parseInt(root.getPropertyValue('--DesktopSizeS')),
            LaptopSize: parseInt(root.getPropertyValue('--LaptopSize')),
            TabletSize: parseInt(root.getPropertyValue('--TabletSize')),
            MobileSize: parseInt(root.getPropertyValue('--MobileSize')),
        };

        this.setState({ screenSizes });
    }

    //! Component Did Mount
    componentDidMount() {
        window.addEventListener('resize', this.screenResize);
        document.addEventListener('keydown', this.escKeydown, false);

        this.getScreenSizes();
        this.screenResize();
    }

    //! Component Will Unmount
    componentWillUnmount() {
        window.removeEventListener('resize', this.screenResize);
        document.removeEventListener('keydown', this.escKeydown, false);
    }

    //! Enable Preloader
    enablePreloader() {
        !this.state.preloader && this.setState({ preloader: true });
    }

    //! Disable Preloader
    disablePreloader() {
        this.state.preloader &&
            setTimeout(() => {
                this.setState({ preloader: false });
            }, 500);
    }

    //! Add Error Message
    addErrorMsg(type, data) {
        if (type === 'form') {
            const errors = Object.fromEntries(Object.entries(data).map(([key, value]) => [key, value[0]]));

            this.setState({
                formError: {
                    error: true,
                    names: Object.keys(errors),
                    msg: errors,
                },
            });
        } else {
            this.setState({
                systemError: {
                    error: true,
                    msg,
                },
            });
        }
    }

    //! Remove Error Message
    removeErrorMsg(type) {
        type === 'form'
            ? this.setState({
                formError: {
                    error: false,
                    names: [],
                    msg: {},
                },
            })
            : this.setState({
                systemError: {
                    error: false,
                    msg: '',
                },
            });
    }

    //! Add High Component
    addHighComponent(component = null) {
        this.setState({
            highComponent: component,
        });
    }

    //! Remove High Component
    removeHighComponent() {
        this.setState({
            highComponent: null,
        });
    }

    //! Open Popup
    openPopup(popupComponent = null, popupProps = {}) {
        // this.disableDomScroll()

        this.setState({
            popupIsOpen: true,
            popupComponent,
            popupProps,
        });
    }

    //! Close Popup
    closePopup() {
        this.enableDomScroll();

        this.setState({
            popupIsOpen: false,
            popupComponent: null,
            ...errorsInitialState,
        });
    }

    //! Disable DOM Scroll
    disableDomScroll() {
        // document.querySelector('html').classList.add('hide-scroll')

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        // if any scroll is attempted, set this to the previous value
        window.onscroll = function (e) {
            e.preventDefault();
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    //! Enable DOM Scroll
    enableDomScroll() {
        // document.querySelector('html').classList.remove('hide-scroll')

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        window.onscroll = function () { };
        window.scrollTo(scrollLeft, scrollTop);
    }

    render() {
        return (
            <UIContext.Provider value={{ ...this.state, ...this.methods }}>
                <ThemeProvider theme={themes}>
                    <HelperClass />
                    <Variables />
                    <Typography />

                    {this.props.children}
                </ThemeProvider>
            </UIContext.Provider>
        );
    }
}

export default UIProvider;
