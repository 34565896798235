'use client';

import React, { createContext, useEffect, useState } from 'react';
import { useParams, usePathname } from 'next/navigation';
import _ from 'lodash';

//* Helpers
import { getCookie, setCookie } from '@/helpers';

//* Translations
import Languages from '../../translations';

const LanguageContext = createContext(null);

const LanguageProvider = (props) => {
    //! Next navigation
    const params = useParams();
    const pathname = usePathname();

    //! State
    const [state, setState] = useState({
        selectedLang: props.selectedLang,
        languages: Languages,
    });

    const translate = (val, vars) => {
        const compiled = _.isString(val) && state.languages[state.selectedLang][val] && _.template(state.languages[state.selectedLang][val]);
        return (compiled && compiled(vars)) || val || 'Please Set Translate Value';
    }

    useEffect(() => {
        setCookie('lang', params.lang)
    }, [params, pathname])

    const methods = {
        translate,
    };

    return (
        <LanguageContext.Provider value={{ ...state, ...methods }}>
            {props.children}
        </LanguageContext.Provider>
    );
}

export default LanguageProvider;
export const LanguageConsumer = LanguageContext.Consumer;
